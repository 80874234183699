function formatCurrency(amount, currency = "MXN") {
  let formatCurrency = new Intl.NumberFormat("es-MX", {
    style: "currency",
    currency: currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return formatCurrency.format(amount);
}

export default formatCurrency;
