<template lang="pug">
div
	//- pre | {{ invoices }}
	.separator()
	div(v-if="showOption")
		AppButton.mt-3(v-if="!loading && invoices.length > 0 && page === 1" color="danger" :label="$t('quickpayment.clean')" @click="clean")
	AppCard(:title="service.name + ' - ' + $t('invoices.message_empty')" v-if="empty && service.name ").mt-4
		h1 
		.plan
			.plan__icon(:class="service.estatus.toLowerCase()")
					i.uil(:class="icon")
			.plan__info
					.client_name_label {{ $t("invoices.partner_id") }} 
					.client_name {{ service.partner_id[1] }}
					.service
						strong {{ $t("invoices.plan") }}: 
						span {{ service.plan_id[1] }}
					.reference
						strong {{ $t("invoices.reference") }}: 
						span {{ service.referencia_bancaria }}
					.description
						strong {{ $t("invoices.next_invoice_date") }}: 
						span {{ service.fecha_proxima_factura }}
					.description
						strong {{ $t("invoices.subscription") }}: 
						span {{ service.subscription_status }}
	AppCard(:title="service.name" v-if="!loading && invoices.length > 0").mt-4
		.plan
			.plan__icon(:class="service.estatus.toLowerCase()")
					i.uil(:class="icon")
			.plan__info
					.client_name_label {{ $t("invoices.partner_id") }} 
					.client_name {{ service.partner_id[1] }}
					.service
						strong {{ $t("invoices.plan") }}: 
						span {{ service.plan_id[1] }}
					.reference
						strong {{ $t("invoices.reference") }}: 
						span {{ service.referencia_bancaria }}
					.description
						strong {{ $t("invoices.next_invoice_date") }}: 
						span {{ service.fecha_proxima_factura }}
					.description
						strong {{ $t("invoices.subscription") }}: 
						span {{ service.subscription_status }}
					.summary
						.row
							.col
								strong {{ $t("invoices.summary_amount") }}:
								br
								span {{formatCurrency(amount)}}
							.col
								strong {{ $t("invoices.summary_payment") }}:
								br
								span {{formatCurrency(payment)}}
							.col
								strong {{ $t("invoices.summary_pending") }}:
								br
								span {{formatCurrency(pending)}}
					AppButton(color="primary" :label="$t('invoices.pay')" :disabled="payment <= 0 || service.subscription_status || !btn_payment_active" @click="pay").mt-3
	span.label.span.mt-2 {{ $t("invoices.confirm") }}
	AppAlert.mb-2.mt-4(:title="service.name" :subtitle="$t('invoices.subscription_helper')" v-if="service.subscription_status" type="info")
	AppAlert.mb-2.mt-4(:title="$t('quickpayment.notfound_title')" :subtitle="$t('quickpayment.notfound_subtitle')" v-if="paymentError")

	AppInvoice.mt-4(v-for="(invoice, index) in invoices" :invoice="invoice" v-if="page == 1")
		label(class="label action")
			input(type="checkbox" v-model="invoice.state_to_pay" class="checkbox checkbox-item" @change="changeState(index)" :disabled="service.subscription_status")
			span(class="span") {{ $t("invoices.pay") }}
</template>

<script>
import { computed } from "@vue/runtime-core";
import { onMounted, ref } from "vue";
import "mosha-vue-toastify/dist/style.css";
import { store } from "@/store";

import AppAlert from "@/components/AppAlert";
import AppButton from "@/components/AppButton";
import AppCard from "@/components/AppCard";
import AppInvoice from "@/components/AppInvoiceItem";
import AppCheckout from "@/components/Form/AppCheckout";
import AppCheckbox from "@/components/Form/AppCheckbox";
import AppDescription from "@/components/AppDescription";

import formatCurrency from "@/utils/formatCurrency";
import { axios } from "@/utils/axios";

export default {
  components: {
    AppAlert,
    AppButton,
    AppCard,
    AppInvoice,
    AppCheckout,
    AppCheckbox,
    AppDescription,
  },
  props: {
    invoices: {
      type: Array,
      default: () => [],
    },
    service: {
      type: Object,
      default: () => ({}),
    },
    hasError: {
      type: Boolean,
      default: false,
    },
    serviceNumber: {
      type: String,
      required: true,
    },
    showOption: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const loading = ref(false);
    const page = ref(1);
    const paymentError = ref(false);
    const list_invoices_to_be_paid = ref([]);
    const checkTest = ref(true);
    const indexLastInvoice = ref(0);
    const link_payment = ref("");
    const btn_payment_active = ref(true);

    onMounted(() => {
      store.APP_LOADING = false;
      //   list_invoices_to_be_paid.value = props.invoices;
      // list_invoices_to_be_paid.value = props.invoices.map((invoice) => ({
      //   id: invoice.id,
      //   name: invoice.name,
      //   invoice_date: invoice.invoice_date,
      //   amount_residual: invoice.amount_residual,
      //   state_to_pay: true,
      // }));
    });

    const empty = computed(() => {
      return props.invoices.length === 0;
    });

    const amount = computed(() => {
      return props.invoices.reduce(
        (sum, invoice) => sum + invoice.amount_residual,
        0
      );
    });

    const payment = computed(() => {
      return props.invoices
        .filter((item) => item.state_to_pay)
        .reduce((sum, invoice) => sum + invoice.amount_residual, 0);
    });

    const pending = computed(() => {
      return amount.value - payment.value;
    });

    const clean = () => {
      emit("update:invoices", []);
    };

    const icon = computed(() => {
      const iconos = {
        activo: "uil-wifi",
        suspendido: "uil-wifi-slash",
        cancelado: "uil-wifi-slash",
      };

      return iconos[props.service.estatus.toLowerCase()];
    });

    const changeState = (index) => {
      console.log(index);
      const selected = props.invoices[index];
      console.log(props.invoices[index].state_to_pay);
      if (selected.state_to_pay == false) {
        // check false
        if (index === indexLastInvoice.value) {
          console.log("is last invoice (false)");
          indexLastInvoice.value += 1;
        } else {
          console.log("Not last invoice (false)");
          selected.state_to_pay = !selected.state_to_pay;
        }
      } else {
        // check true
        if (index === indexLastInvoice.value - 1) {
          console.log("is last invoice (true)");
          indexLastInvoice.value -= 1;
        } else {
          console.log("Not last invoice (true)");
          selected.state_to_pay = !selected.state_to_pay;
        }
      }
    };

    const print = () => {
      console.log(indexLastInvoice.value);
    };

    const pay = async () => {
      console.log("pay processing...");
      try {
        btn_payment_active.value = false;
        const pathCheckout = process.env.VUE_APP_CHECKOUT_URL;
        const ids = props.invoices
          .filter((item) => item.state_to_pay)
          .map((item) => item.id);
        const invoice_ids = `${ids.join(",")}`;
        const jsonRequest = {
          currency: "mxn",
          name: `${props.service.name} - ${props.service.plan_id[1]}`,
          amount: Math.round(payment.value * 100),
          quantity: 1,
          metadata: {
            description: invoice_ids,
            reference: props.service.name,
            folio: "multipayment",
            invoice_ids: invoice_ids,
            quantity: ids.length,
          },
          description: "multipayment",
        };
        // console.log(pathCheckout);
        // console.log(jsonRequest);
        const { data } = await axios.post(
          `${pathCheckout}multiple`,
          jsonRequest
        );
        // console.log(data);
        // console.log(data.data.url);
        window.location.href = data.data.url;
      } catch (error) {
        console.log(error);
        paymentError.value = true;
      } finally {
        btn_payment_active.value = true;
      }
    };

    return {
      page,
      clean,
      loading,
      paymentError,
      icon,
      checkTest,
      list_invoices_to_be_paid,
      formatCurrency,
      amount,
      payment,
      pending,
      changeState,
      indexLastInvoice,
      print,
      pay,
      link_payment,
      empty,
      btn_payment_active,
    };
  },
};
</script>

<style lang="scss" scoped>
.row {
  @apply grid;
  @apply grid-flow-col;
  @apply auto-cols-fr;
  @apply gap-4;
}

.client_name_label {
  @apply text-sm;
  @apply text-bluegray-600;
  @apply font-medium;
}

.client_name {
  @apply text-bluegray-500;
}

.service {
  @apply text-bluegray-400;
  @apply text-xs;
}

.reference {
  @apply text-bluegray-400;
  @apply text-xs;
}

.description {
  @apply text-bluegray-400;
  @apply text-xs;
}

.payment__summary {
  @apply flex;
  @apply flex-col;
  @screen md {
    @apply flex-row;
  }
}

.summary {
  @apply text-bluegray-400;
  @apply text-base;
  @apply text-center;
}

.separator {
  @apply border-t;
  @apply border-bluegray-200;
  @apply mt-4;
}

.plan {
  @apply flex;
  @apply items-center;
  @apply h-full;
  @apply cursor-pointer;

  &__info {
    @apply flex-1;
    @apply overflow-hidden;
  }

  &__summary {
    @apply flex-1;
    @apply overflow-hidden;
  }

  &__icon {
    @apply w-24;
    @apply h-24;
    @apply rounded-lg;
    @apply items-center;
    @apply justify-center;
    @apply mr-3;
    @apply hidden;
    @screen sm {
      @apply flex;
    }

    i {
      @apply text-2xl;
    }

    &.activo {
      @apply bg-gignetgreen-200;
      i {
        @apply text-gignetgreen-600;
      }
    }
    &.suspendido {
      @apply bg-gignetblue-200;
      i {
        @apply text-gignetblue-600;
      }
    }
  }

  &__row {
    @apply flex;
    @apply flex-col;
    @screen sm {
      @apply flex-row;
      @apply items-center;
    }
  }

  &__name {
    @apply text-bluegray-700;
    @apply font-medium;
  }

  &__domicilio {
    @apply text-sm;
    @apply truncate;
    @apply text-bluegray-500;
    @apply mb-1;
  }

  &__id {
    @apply text-bluegray-400;
    @apply uppercase;
    @apply text-xs;
    @apply mr-4;
  }

  &__tipo {
    @apply text-bluegray-600;
    @apply text-xs;
    @apply mb-2;
    @apply tracking-wide;
  }

  &__fecha {
    @apply flex;
    @apply flex-col;
    @apply text-xs;
    & + & {
      @apply mt-2;
      @screen sm {
        @apply mt-0;
        @apply ml-6;
      }
    }
    h4 {
      @apply font-medium;
      @apply text-bluegray-600;
    }
    span {
      @apply text-bluegray-500;
      @apply capitalize;
    }
  }

  &__additional {
    @apply flex;
    @apply flex-col;
    @apply text-xs;
    & + & {
      @apply mt-2;
      @screen sm {
        @apply mt-0;
        @apply ml-6;
      }
    }
    h4 {
      @apply font-medium;
      @apply text-bluegray-600;
    }
    span {
      @apply text-bluegray-500;
      @apply capitalize;
    }
  }

  &__arrow {
    @apply text-3xl;
    @apply text-bluegray-300;
  }
}

.checkbox-item {
  padding-left: 0.8rem !important;
  padding-right: 0.8rem !important;
  padding-top: 0.8rem !important;
  padding-bottom: 0.8rem !important;
}

.checkbox {
  @apply rounded-md;
  @apply border-gray-300;
  @apply shadow-sm;
  @apply px-2;
  @apply py-2;
  @apply text-gignetgreen-400;
  @apply transition-all;
  @apply outline-none;
  &:focus:invalid {
    @apply border-red-300;
    @apply shadow-red-300-md;
  }
  &:focus {
    @apply border-gignetblue-300;
    @apply ring;
    @apply ring-gignetblue-200;
    @apply ring-opacity-50;
    @apply ring-offset-2;
  }

  &.disabled {
    @apply bg-bluegray-50;
    @apply text-bluegray-400;
  }
}

.label {
  @apply mb-3;
  @apply flex;
  @apply items-center;
}

.span {
  @apply text-bluegray-400;
  @apply text-xs;
  @apply ml-2;
  @apply leading-tight;
  @apply text-justify;
}

.action {
  position: relative !important;
}
</style>
