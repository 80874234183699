<template lang="pug">
form(:action="pathCheckout" method="POST")
  input(type="text" name="currency" class="hidden"  	v-model="formData.currency")
  input(type="text" name="name" class="hidden"  			v-model="formData.name")
  input(type="text" name="amount" class="hidden"  		v-model="formData.amount")
  input(type="text" name="quantity" class="hidden"  	v-model="formData.quantity")
  input(type="text" name="description" class="hidden"  v-model="formData.description")
  input(type="text" name="reference" class="hidden"  v-model="formData.reference")
  input(type="text" name="folio" class="hidden"  		v-model="formData.folio")
  div(v-if="ShowCheckTerms")
    AppCheckbox(:label="$t('invoices.confirm')" v-model="checkvalue" )
    AppButton(:label="$t('quickpayment.pay')" :disabled="!checkvalue || invoice.payment_state === 'paid'")
  AppButton(v-else :label="$t('quickpayment.pay')" :disabled="disabled || invoice.payment_state === 'paid'")
</template>

<script>
import { onMounted } from "vue";
import { reactive, ref } from "@vue/reactivity";
import AppCheckbox from "@/components/Form/AppCheckbox";
import AppButton from "@/components/AppButton";
// import { axios } from "@/utils/axios";
export default {
  components: {
    AppButton,
    AppCheckbox,
  },
  props: {
    name: String,
    invoice: [Object, null],
    ShowCheckTerms: {
      type: Boolean,
      default: true,
    },
    disabled: Boolean,
  },
  setup(props) {
    const checkvalue = ref(false);
    const pathCheckout = process.env.VUE_APP_CHECKOUT_URL;
    const formData = reactive({
      currency: "mxn",
      name: props.name,
      amount: Math.ceil(props.invoice.amount_residual.toFixed(2) * 100),
      quantity: 1,
      description: props.invoice.id,
      reference: props.invoice.servicio_id[1].toUpperCase(),
      folio: props.invoice.name,
    });

    // const validatePaymentStatus = async (id) => {
    //   const { data } = await axios.get(`payment_history/${id}`);
    //   console.log("---");
    //   console.log(data);
    //   console.log("---");
    //   if (data.payload.length > 0) {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // };

    onMounted(async () => {
      console.log("onMounted AppCheckoutForm");
    });
    return { formData, pathCheckout, checkvalue };
  },
};
</script>

<style></style>
